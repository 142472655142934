import moment from 'moment';
import 'moment-duration-format';

const DURATION_FORMAT = 'Y[+] __ M[+] __';
const DURATION_FORMAT_OPTIONS = {
  largest: 1
};
const FS_DATE_FORMAT = 'YYYY_MM_DD';
const MONTH_YEAR_FORMAT = 'MMMM YYYY';

export function getDurationFromDate(dateStr) {
  const dateFrom = moment(dateStr);
  const durartion = moment.duration(moment().diff(dateFrom));
  return durartion.format(DURATION_FORMAT, DURATION_FORMAT_OPTIONS);
}

export function getFSDate(dateTimeStr) {
  return moment(dateTimeStr).format(FS_DATE_FORMAT);
}

export function getMonthYear(dateTimeStr) {
  return moment(dateTimeStr).format(MONTH_YEAR_FORMAT);
}
