import React from "react";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";

const SEO = props => (
  <StaticQuery
    query={detailsQuery}
    render={data => {
      const title = props.title;
      return (
        <Helmet
          htmlAttributes={{
            lang: "en",
          }}
          title={title}
          titleTemplate={`%s - ${data.site.siteMetadata.title}`}
          defaultTitle={data.site.siteMetadata.title}
          meta={props.meta}
        />
      );
    }}
  />
);

SEO.defaultProps = {
  lang: "en",
  keywords: [],
  meta: []
};

export default SEO;

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
